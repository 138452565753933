import React from 'react';
import { StarFilled } from '@/assets/icons';
import { LazyImage } from '..';
import { UrlLink } from '@/configs/urlLink';
import Link from 'next/link';

interface ICardExchangeProps {
  isLoading?: boolean;
  src?: string | null;
  title?: string;
  score?: string;
  desc?: string | null;
  slug?: string;
  type?: string;
}

export default function CardExchange({
  isLoading = true,
  score,
  desc,
  src,
  title,
  slug,
}: ICardExchangeProps) {
  return (
    <>
      {isLoading ? (
        <div className="bg-gray-variants-2 flex w-[424px] flex-col items-center gap-4 rounded-[10px] p-4">
          <div className="m-x-8 h-[226px] w-full animate-pulse bg-gray-200" />
          <div className="flex w-full items-center justify-between ">
            <div className="h-12 w-48 animate-pulse bg-gray-200" />
            <div className="h-8 w-10 animate-pulse bg-gray-200" />
          </div>
          <div className="min-h-[360px] w-full animate-pulse bg-gray-200" />
          <div className="h-12 w-full animate-pulse rounded-[10px] bg-gray-200" />
        </div>
      ) : (
        <div className="border-neutral-grayLight flex h-max min-h-full flex-col items-center gap-4 rounded-lg border-[0.5px] bg-white">
          <div className="relative flex h-[90px] w-full items-center bg-[#ccc]">
            <div className="relative h-full w-full bg-white">
              <LazyImage
                className="rounded-lg object-contain"
                src={src}
                fill
                alt={title || ''}
                sizes="(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 25vw"
              />
            </div>
          </div>

          <div className="flex w-full flex-col items-start justify-between px-4 lg:flex-row lg:items-center">
            <Link
              href={`${UrlLink.broker.index}/${slug}`}
              className="hover:text-primary-eastern text-PC-Title-Large font-sans"
            >
              {title}
            </Link>
            <div className="text-sub2 flex items-center gap-x-1">
              <p className="text-primary-eastern flex items-center justify-center gap-1 text-xl font-bold">
                {Number(score).toFixed(1)}
                <StarFilled className="h-4 w-4" />
              </p>
            </div>
          </div>
          <div className="mb-3 line-clamp-3 h-full md:mb-0 md:line-clamp-4">
            <p className="md:text-PC-Body-Small px-4 text-justify font-sans text-[10px] leading-[14px] md:pb-4">
              {desc}
            </p>
          </div>
        </div>
      )}
    </>
  );
}
